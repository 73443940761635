import { isLocalServer } from "./custom_utils";

const devSubDomain = window.location.hostname.includes("dev") ? "dev." : "";
const LOCAL_URL = "http://localhost:3020/apis/v1";
const PUBLIC_URL = `https://${devSubDomain}mothrift.com/apis/v1`;

export const API_URL = isLocalServer() ? LOCAL_URL : PUBLIC_URL;
export const SOCKET_URL = isLocalServer()
  ? "http://localhost:3019"
  : `https://${devSubDomain}mothrift.com`;
export const Payment_API_URL = isLocalServer()
  ? "http://localhost:3019/api/teasypay"
  : `https://${devSubDomain}payments.mothrift.com/api/teasypay`;
